@use "tailwindcss/base";
@use "tailwindcss/components";
@use "tailwindcss/utilities";

$font-base-url: "https://static3.industrybuying.com/a-wk/fonts";

.container {
  @apply sm:max-w-[90%] max-w-[100%];
}

@layer base {
  /* Regular */
  @font-face {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: url("#{$font-base-url}/poppins/Poppins-Regular.ttf") format('truetype');
  }

  /* Bold */
  @font-face {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 700;
    font-display: swap;
    src: url("#{$font-base-url}/poppins/Poppins-Bold.ttf") format('truetype');
  }

  /* Italic */
  @font-face {
    font-family: 'Poppins';
    font-style: italic;
    font-weight: 400;
    font-display: swap;
    src: url("#{$font-base-url}/poppins/Poppins-Italic.ttf") format('truetype');
  }

  /* Medium */
  @font-face {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-display: swap;
    src: url("#{$font-base-url}/poppins/Poppins-Medium.ttf") format('truetype');
  }

  /* SemiBold */
  @font-face {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-display: swap;
    src: url("#{$font-base-url}/poppins/Poppins-SemiBold.ttf") format('truetype');
  }
  
}
