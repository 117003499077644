/* You can add global styles to this file, and also import other style files */

/**
 *Global reset of all HTML elements
 */

html,
body {
  margin: 0;
  padding: 0;
}

*,
:after,
:before {
  box-sizing: border-box;
}

h1,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
cite,
code,
del,
dfn,
img,
q,
s,
samp,
small,
strike,
sub,
sup,
tt,
var,
dd,
dl,
dt,
li,
ol,
ul,
fieldset,
form,
label,
legend,
button,
table,
caption,
figure,
tbody,
tfoot,
thead,
tr,
th,
td {
  margin: 0;
  padding: 0;
  border: 0;
  font-weight: normal;
  font-style: normal;
  font-size: 100%;
  line-height: 1;
  font-family: inherit;
}

button { background: transparent }