/*
 * Entry point of global application style.
 * Component-specific style should not go here and be included directly as part of the components.
 */
@use "@angular/material" as mat;
@include mat.core();

body,
html {
  font-family: var(--font-family);
}

body {
  background: #f2f2f2;
  min-width: 100vw;
  overflow-x: hidden;
}

@media (min-width:767px) {

  .container { padding-left: 10px; padding-right: 10px; }
  .container .container { padding-left: 0px; padding-right: 0px; }
 
}

@media (width: 950px) {
  body {
    min-width: 950px;
  }
}

.no-scrollbar {
  overflow-x: auto;
  scrollbar-width: none;

  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
.no-scrollbar::-webkit-scrollbar {
  display: none;
}

:root {
  --font-family: 'Poppins', 'Segoe UI', 'Roboto', 'Arial', sans-serif;
  --mdc-dialog-container-shape: 0px;
  --mat-dialog-container-max-width: 100%;
  --mdc-dialog-container-color: #00000000;
  --mdc-outlined-text-field-error-label-text-color: #000000;
  --mdc-outlined-text-field-focus-label-text-color: #000000;
  --mdc-outlined-text-field-error-focus-label-text-color: #000000;
  --mdc-outlined-text-field-focus-outline-color: #000000;
  --mdc-filled-text-field-focus-label-text-color: #000000;
  --mdc-filled-text-field-error-label-text-color: #000000;
  --mdc-filled-text-field-focus-active-indicator-color: #000000;
  --mdc-filled-text-field-error-focus-label-text-color: #000000;
  --mdc-filled-text-field-error-focus-active-indicator-color: #000000;
  --mdc-outlined-text-field-error-focus-outline-color: #000000;
  --mdc-outlined-text-field-error-outline-color: #000000;
  --mat-bottom-sheet-container-background-color: #ffffff;
  --mat-stepper-header-selected-state-icon-background-color: #F94D00;
  --mdc-filled-text-field-container-color: #ffffff;
  --mat-select-trigger-text-font: var(--font-family);
}

$theme: mat.define-theme(
  (
    color: (
      theme-type: light,
      primary: mat.$red-palette,
    ),
  )
);

.mat-mdc-progress-spinner {
  --mdc-circular-progress-active-indicator-color: #162f4d;
}
.mat-mdc-form-field
  .mat-mdc-text-field-wrapper
  .mat-mdc-form-field-flex
  .mat-form-field-infix {
  width: 100% !important;
}

// css for header bottom bar
.mat-mdc-list-base {
  display: flex !important;
}

// .mat-mdc-select .mat-mdc-select-trigger .mat-mdc-select-arrow-wrapper {
//   display: none;
// }

.mat-badge-content {
  width: inherit !important;
}

.bottom-sheet {
  background-color: white;
  width: 100%;
  max-width: 100% !important;
}

.popup-dialog {
  background-color: white;
}

.popup-dialog-rounded {
  background-color: white;
  border-radius: 10px;
}

// css for seo content
.content-only {
  padding-left: 10px;
  padding-right: 10px;
  font-size: 14px;
}
.content-only * {
  font-size: 14px !important;
  line-height: 19px !important;
  color: #2f2f2f !important;
}
.content-only h1,
.content-only h2,
.content-only h3,
.content-only h4,
.content-only h5,
.content-only h6 {
  font-weight: bold !important;
  line-height: normal !important;
  color: #162f4d !important;
}

.content-only h1,
.content-only h1 * {
  font-size: 18px !important;
}
.content-only h2,
.content-only h2 * {
  font-size: 16px !important;
}
.content-only h3,
.content-only h3 * {
  font-size: 16px !important;
}
.content-only h4,
.content-only h4 * {
  font-size: 15px !important;
}
.content-only h5,
.content-only h5 * {
  font-size: 14px !important;
}
.content-only h6,
.content-only h6 * {
  font-size: 14px !important;
}

.content-only a {
  color: #f94d00 !important;
}

.content-only table {
  width: 100% !important;
  border-collapse: collapse !important;
}
.content-only table td,
.content-only table th {
  padding: 10px !important;
  border: 1px #dfdfdf solid !important;
}
.content-only table th {
  color: #000 !important;
}
.content-only ul {
  margin: 0px 0px 0px 15px !important;
}
.content-only ol{
  padding-left: 15px;
}

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
html {
  @include mat.all-component-themes($theme);
}

button {
  @apply cursor-pointer;
}

ul {
  @apply list-none;
}

/* bulk discount */
.pro-bulk-discount .mdc-text-field--filled {
  border-radius: 5px !important;
  border: 1px solid #e9e9e9;
  height: 31px;
}
.pro-bulk-discount .mdc-text-field {
  padding: 0px !important;
}
.pro-bulk-discount .mdc-floating-label {
  margin: 1px 0px 0px 2px;
}
.pro-bulk-discount .mat-mdc-form-field-infix {
  padding: 1px 9px 2px 99px !important;
  min-height: 1px !important;
  height: 30px !important;
  display: flex;
  align-content: center;
  justify-content: center;
}

.pro-bulk-discount
  .mat-mdc-text-field-wrapper
  .mat-mdc-form-field-infix
  .mat-mdc-floating-label {
  left: 2px;
  right: auto;
  top: 34px !important;
  height: 30px;
}
.pro-bulk-discount .mat-mdc-select-value-text,
.pro-bulk-discount .mat-mdc-select-value {
  overflow: visible !important;
  font-size: 12px;
}
.pro-bulk-discount
  .mat-mdc-text-field-wrapper
  .mat-mdc-form-field-infix
  .mat-mdc-floating-label
  mat-label {
  font-size: 13px !important;
}

.pro-bulk-discount .mat-mdc-select-arrow {
  margin-top: 0px !important;
}
.pro-bulk-discount .mdc-line-ripple {
  display: none;
}
.pro-bulk-discount
  .mat-mdc-form-field-subscript-wrapper.mat-mdc-form-field-bottom-align {
  display: none;
}
div.mat-mdc-select-panel {
  background: #ffffff !important;
  padding: 5px !important;
}
.mat-mdc-option:focus.mdc-list-item,
.mat-mdc-option.mat-mdc-option-active.mdc-list-item {
  background-color: #f4f6f8 !important;
}
.mat-pseudo-checkbox {
  display: none !important;
}
.pro-bulk-discount .mat-mdc-select-value {
  text-align: center;
}
div.mat-mdc-select-panel .mat-mdc-option:hover:not(.mdc-list-item--disabled) {
  // background-color: var(--mat-option-hover-state-layer-color);
  box-shadow: 2px 2px 4px #00000021 !important;
  background-color: #ffffff !important;

  transition: 1s !important;
}
div.mat-mdc-select-panel .mat-mdc-option {
  padding: 4px !important;
  border-bottom: 1px solid #eee !important;
  margin-bottom: 8px !important;
}

.pro-bulk-discount .mat-mdc-select-arrow {
  margin-top: 15px !important;
}
.pro-bulk-discount .mdc-line-ripple {
  display: none;
}
.pro-bulk-discount
  .mat-mdc-form-field-subscript-wrapper.mat-mdc-form-field-bottom-align {
  display: none;
}
div.mat-mdc-select-panel {
  background: #ffffff !important;
}
.mat-mdc-option:focus.mdc-list-item,
.mat-mdc-option.mat-mdc-option-active.mdc-list-item {
  background-color: #f4f6f8 !important;
}
.mat-pseudo-checkbox {
  display: none !important;
}

.pro-bulk-discount .mat-mdc-select {
  padding-left: 0px;
}

@media (max-width: 600px) {
  .pro-bulk-discount .mat-mdc-select {
    padding-left: 0px;
  }
}

/* bulk discount end */

/* video */
.youtube-player-placeholder,
youtube-player iframe {
  position: absolute;
  left: 0px;
  top: 0px;
  right: 0px;
  bottom: 0px;
  width: 100% !important;
  height: 100% !important;
}
/* video  END*/
.tab-flex.mat-mdc-tab-group.mat-mdc-tab-group-stretch-tabs
  > .mat-mdc-tab-header
  .mat-mdc-tab {
  flex-grow: 0 !important;
}

/* dropdown */
// .select-1 {
//   width: 160px;
// }
.select-1 .mdc-text-field--filled {
  background: none !important;
}
.select-1 .mdc-line-ripple {
  display: none !important;
}
.select-1 .mat-mdc-form-field-bottom-align {
  display: none !important;
}
.select-1 .mat-mdc-select-value-text {
  display: none !important;
}
.select-1 .mat-mdc-form-field-infix {
  min-height: 0px;
  padding-top: 3px !important;
  padding-bottom: 3px !important;
}
.select-1 .mdc-text-field .mdc-floating-label {
  transform: translateY(-110%) !important;
  color: #000 !important;
}
.select-1 .mdc-list-item__primary-text {
  font-size: 14px !important;
}
.select-1 .mat-select-trigger {
  float: right;
}

.select-1 {
  height: 38px;
}
.select-1 .mat-mdc-form-field-infix {
  width: auto !important;
}
.select-1 .mat-mdc-floating-label {
  max-width: 100% !important;
  position: relative !important;
}
.select-1 .mat-mdc-select {
  display: none;
}
.select-1 .mat-icon {
  margin-top: 6px;
}
// .cdk-overlay-pane {
//   width: 200px !important;
// }

/* dropdown end */
.mat-bottom-sheet-container {
  padding: 0px !important;
}

/* scroll */
::-webkit-scrollbar {
  width: 3px;
  height: 3px;
}
::-webkit-scrollbar-track {
  background-color: transparent;
  border-radius: 10px;
}
::-webkit-scrollbar-thumb {
  background: #949494;
  border-radius: 10px;
}
/* scroll end */

/* tabs */
.tabs-fill .mdc-tab-indicator--active {
  background: #f94d00 !important;
}
.tabs-fill .mdc-tab-indicator--active .mdc-tab__text-label {
  color: #ffffff !important;
}
.tabs-fill .mat-mdc-tab .mdc-tab-indicator__content--underline {
  display: none;
}
.mat-mdc-tab-body-wrapper p,
.mat-mdc-tab-body-wrapper ul li {
  font-size: 14px;
  line-height: 24px;
}
mat-tab-group {
  width: 100%;
}
/* tabs end */

.m-menu .m-menu-in {
  width: 350px !important;
}


html.cdk-global-scrollblock { 
   position: static !important; 
   top: auto !important; 
  //  overflow: hidden !important;
}