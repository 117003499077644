@use "sass:math";

@for $i from 1 through 12 {
  .col-span-#{$i} {
    width: math.percentage(math.div($i, 12));
  }

  // width
  .w-#{$i}\/12 {
    width: math.percentage(math.div($i, 12));
  }
}

@for $i from 1 through 6 {
  // padding vertical
  $py: $i * 0.25;
  .py-#{$i} {
    padding-top: $py * 1rem; /* 1rem = 16px */
    padding-bottom: $py * 1rem; /* 1rem = 16px */
  }

  //  column gap
  $gap_x: $i * 0.25;
  .gap-x-#{$i} {
    padding-top: $gap_x * 1rem; /* 1rem = 16px */
    padding-bottom: $gap_x * 1rem; /* 1rem = 16px */
  }
}
